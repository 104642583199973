<template>
  <div class="product">
    <div class="sec">
      <div class="title">超级主机</div>
      <div class="dvBox">
        <div class="dv">
          <router-link to="/pc/ProGateway"  class="imgBox">
            <img
              src="../../../assets/product/product1.png"
              style="height:1.64rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProGateway" class="name">超级主机</router-link>
        </div>
      </div>
    </div>
    <div class="sec">
      <div class="title">智能面板</div>
      <div class="dvBox">
        <div class="dv">
          <router-link to="/pc/ProSwitch" class="imgBox">
            <img
              src="../../../assets/product/product2.png"
              style="width:1.64rem;height:1.64rem"
              class="img"
            />
          </router-link>
          <router-link to="/pc/ProSwitch"  class="name">智能开关面板</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProScene" class="imgBox">
            <img
              src="../../../assets/product/product3.png"
              style="width:1.64rem;height:1.64rem"
              class="img"
            />
          </router-link>
          <router-link to="/pc/ProScene" class="name">双开场景面板</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProCard" class="imgBox">
            <img
              src="../../../assets/product/product4.png"
              style="width:1.64rem;height:1.64rem"
              class="img"
            />
          </router-link>
          <router-link to="/pc/ProCard" class="name">插卡取电面板</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProUsb" class="imgBox">
            <img
              src="../../../assets/product/product5.png"
              style="width:1.64rem;height:1.64rem"
              class="img"
            />
          </router-link>
          <router-link to="/pc/ProUsb" class="name">五孔带Usb电源面板</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProAir" class="imgBox">
            <img
              src="../../../assets/product/product6.png"
              style="width:1.64rem;height:1.64rem"
              class="img"
            />
          </router-link>
          <router-link to="/pc/ProAir" class="name">空调面板</router-link>
        </div>
      </div>
    </div>
    <div class="sec">
      <div class="title">窗帘电机</div>
      <div class="dvBox">
        <div class="dv">
          <router-link to="/pc/ProCurtain" class="imgBox">
            <img
              src="../../../assets/product/product7.png"
              style="width:2.61rem;height:1.57rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProCurtain" class="name">窗帘电机</router-link>
        </div>
        
      </div>
    </div>
    <div class="sec">
      <div class="title">单点</div>
      <div class="dvBox">
        <div class="dv">
          <router-link to="/pc/ProSingleLight" class="imgBox">
            <img
              src="../../../assets/product/product9.png"
              style="width:2.54rem;height:1.93rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleLight" class="name">无线智能LED控制器</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProSingleSwitch" class="imgBox">
            <img
              src="../../../assets/product/product10.png"
              style="width:2.54rem;height:1.87rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleSwitch" class="name">无线单点开关控制器</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProSingleRemote" class="imgBox">
            <img
              src="../../../assets/product/product11.png"
              style="width:2.54rem;height:1.87rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleRemote" class="name">无线单点遥控器</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProSinglePower" class="imgBox">
            <img
              src="../../../assets/product/product12.png"
              style="width:2.54rem;height:1.87rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSinglePower" class="name">无线电源状态检测器</router-link>
        </div>
      </div>
    </div>
    <div class="sec">
      <div class="title">其它控制</div>
      <div class="dvBox">
        <div class="dv">
          <router-link to="/pc/ProSingleLed" class="imgBox">
            <img
              src="../../../assets/product/product13.png"
              style="width:2.42rem;height:1.93rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleLed" class="name">集中式LED调色控制器</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProSingleRGBW" class="imgBox">
            <img
              src="../../../assets/product/product14.png"
              style="width:2.45rem;height:1.54rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleRGBW" class="name">RGBW灯带</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProSingleInfrared" class="imgBox">
            <img
              src="../../../assets/product/product15.png"
              style="width:1.77rem;height:1.71rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleInfrared" class="name">红外人体感应探头</router-link>
        </div>
        <div class="dv">
          <router-link to="/pc/ProSingleHifi" class="imgBox">
            <img
              src="../../../assets/product/product16.png"
              style="width:2.42rem;height:1.42rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleHifi" class="name">Hi-Fi蓝牙功放</router-link>
        </div>
         <div class="dv">
          <router-link to="/pc/ProSingleCheckin" class="imgBox">
            <img
              src="../../../assets/product/product17.png"
              style="width:0.8rem;height:2.46rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleCheckin" class="name">自助入住机</router-link>
        </div>
         <div class="dv">
          <router-link to="/pc/ProSingleRobot" class="imgBox">
            <img
              src="../../../assets/product/product18.png"
              style="width:1.33rem;height:2.3rem"
              class="img img1"
            />
          </router-link>
          <router-link to="/pc/ProSingleRobot" class="name">智能送物机器人</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.product {
  background-color: #fff;
  .sec {
    width: 12rem;
    margin: 0 auto;
    padding-bottom: 0.8rem;
    &:first-of-type {
      padding-top: 1.2rem;
    }
    &:last-of-type{
      padding-bottom: 1.2rem;
    }
    .title {
      margin-bottom: 0.4rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #333333;
      text-align: left;
    }
    .dvBox {
      display: flex;
      width: 12rem;
      flex-wrap: wrap;
      .dv {
        display: flex;
        flex-direction: column;
        width: 3.6rem;
        margin-right: 0.6rem;
        &:nth-of-type(3n) {
          margin-right: 0;
          margin-bottom: 0.39rem;
        }
        .imgBox {
          width: 3.6rem;
          height: 3rem;
          background-color: #f8f8f8;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            transition: all 0.6s;
          }
          &:hover .img {
            transform: scale(1.2, 1.2);
            -moz-transform: scale(1.2, 1.2);
            -webkit-transform: scale(1.2, 1.2);
            -o-transform: scale(1.2, 1.2);
          }
          &:hover .img1 {
            transform: scale(1.1, 1.1);
            -moz-transform: scale(1.1, 1.1);
            -webkit-transform: scale(1.1, 1.1);
            -o-transform: scale(1.1, 1.1);
          }
        }

        .name {
          margin-top: 0.23rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
